// carousel
import { tns } from "tiny-slider/src/tiny-slider";
import "tiny-slider/dist/tiny-slider.css";

//CAROUSEL OPEN
var carouselHome = document.getElementById("b2_slider");
if (typeof carouselHome != "undefined" && carouselHome != null) {
  const btnB2 = document.getElementById("b2_close");
  const mainB2 = document.querySelector(".rommId1");
  var customizedFunctionB2 = function (info, eventName) {
    const closerEl = document.getElementById('room__dvouluzkovy');
    if (closerEl.classList.contains('showRoom')) {
        closerEl.classList.add('hiddenRoom');
      closerEl.classList.remove("showRoom");
        mainB2.classList.toggle('room__close');
    }
  }
  

  let sliderB2 = tns({
    container: "#b2_slider",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
  sliderB2.events.on('transitionStart', customizedFunctionB2);

  btnB2.addEventListener("click", function () {
    mainB2.classList.toggle('room__close');
  });
}
var carouselHome = document.getElementById("b2b_slider");
if (typeof carouselHome != "undefined" && carouselHome != null) {

  const btnB2b = document.getElementById("b2b_close");
  const mainB2b = document.querySelector(".rommId2");
  var customizedFunctionB2B = function (info, eventName) {
    const closerEl = document.getElementById('room__dvouluzkovy-s-balkonem');
    if (closerEl.classList.contains('showRoom')) {
        closerEl.classList.add('hiddenRoom');
        closerEl.classList.remove('showRoom');
        mainB2b.classList.toggle('room__close');
    }
  }
  let sliderB2B = tns({
    container: "#b2b_slider",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
  sliderB2B.events.on('transitionStart', customizedFunctionB2B);
  btnB2b.addEventListener("click", function () {
    mainB2b.classList.toggle('room__close');
  });
}
var carouselHome = document.getElementById("b2bd_slider");
if (typeof carouselHome != "undefined" && carouselHome != null) {

  const btnB2bd = document.getElementById("b2bd_close");
  const mainB2bd = document.querySelector(".rommId3");

  var customizedFunctionB2BD = function (info, eventName) {
    const closerEl = document.getElementById('room__dvouluzkovyDB');
    if (closerEl.classList.contains('showRoom')) {
        closerEl.classList.add('hiddenRoom');
        closerEl.classList.remove('showRoom');
        mainB2bd.classList.toggle('room__close');
    }
  }
  let sliderB2BD = tns({
    container: "#b2bd_slider",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
  sliderB2BD.events.on('transitionStart', customizedFunctionB2BD);
  btnB2bd.addEventListener("click", function () {
    mainB2bd.classList.toggle('room__close');
  });
}
var carouselHome = document.getElementById("f_slider");
if (typeof carouselHome != "undefined" && carouselHome != null) {
  const btnF = document.getElementById("f_close");
  const mainF = document.querySelector(".rommId4");
  var customizedFunctionF = function (info, eventName) {
    const closerEl = document.getElementById('room__rodinnyPokoj');
    if (closerEl.classList.contains('showRoom')) {
        closerEl.classList.add('hiddenRoom');
        closerEl.classList.remove('showRoom');
        mainF.classList.toggle('room__close');
    }
  }
  let sliderF = tns({
    container: "#f_slider",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
  sliderF.events.on('transitionStart', customizedFunctionF);
  btnF.addEventListener("click", function () {
    mainF.classList.toggle('room__close');
  });
}
var carouselHome = document.getElementById("fb_slider");
if (typeof carouselHome != "undefined" && carouselHome != null) {
  const btnFb = document.getElementById("fb_close");
  const mainFb = document.querySelector(".rommId5");
  var customizedFunctionFB = function (info, eventName) {
    const closerEl = document.getElementById('room__rodinnyDvoupokojovy');
    if (closerEl.classList.contains('showRoom')) {
        closerEl.classList.add('hiddenRoom');
        closerEl.classList.remove('showRoom');
        mainFb.classList.toggle('room__close');
    }
  }
  let sliderFB= tns({
    container: "#fb_slider",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
  sliderFB.events.on('transitionStart', customizedFunctionFB);
  btnFb.addEventListener("click", function () {
    mainFb.classList.toggle('room__close');
  });
}

var slider_other1 = document.getElementById("slider_other1");
if (typeof slider_other1 != "undefined" && slider_other1 != null) {
  tns({
    container: "#slider_other1",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
}

var slider_other2 = document.getElementById("slider_other2");
if (typeof slider_other2 != "undefined" && slider_other2 != null) {
  tns({
    container: "#slider_other2",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
}

var slider_other3 = document.getElementById("slider_other3");
if (typeof slider_other3 != "undefined" && slider_other3 != null) {
  tns({
    container: "#slider_other3",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: true,
    controls: true,
    controlsText: ["", ""]
  });
}